<template>
  <div class="body-2 mb-2">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormText'
};
</script>

<style lang="sass" scoped></style>
